@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-2xl md:text-4xl font-bold;
}

h2 {
  @apply text-2xl md:text-4xl font-bold;
}

h3 {
  @apply text-base;
}